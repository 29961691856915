
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class DialogService extends Vue {
  @Prop({ default: 0 }) readonly service_selected!: any;
  private url_frontoffice = process.env.VUE_APP_FRONT_OFFICE_URL as string;

  dialog = false;
  motive_selected: any = {};
  active: Boolean = true;

  private askMoreHelp() {
    this.$emit("moreHelp", true);
    this.dialog = false;
  }

  private cerrar() {
    this.$emit("vaciarInput", false);
  }

  private stepTwoSubmit() {
    this.$store.dispatch("setStateToTwo");
  }

  private backAction() {
    window.open(this.url_frontoffice, "_self");
  }

  private mounted() {
    this.$gtag.event("Servicio: " + this.service_selected.nombre, {
      event_label: "Confirmar Seleccionar servicio",
      event_category: "test",
      value: 1
    });
    if (
      this.service_selected.servicio_url &&
      this.service_selected.servicio_url.length > 0
    ) {
      this.active = false;
    }
  }
}
